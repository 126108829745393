
import Vue from "vue";
import { claimsMapState } from "@/store/modules/claims";
import ClaimStatusLookup from "@/views/shared/lossForm/ClaimsLookup/ClaimStatusLookup.vue";
import ClaimStatusByClaimNumberLookupDetails from "@/views/shared/lossForm/ClaimsLookup/ClaimStatusDetails.vue";
import ClaimStatusByPolicyNumberLookupDetails from "@/views/shared/lossForm/ClaimsLookup/ClaimsLookupResults.vue";
import Help from "@/components/Help/Inquiries.vue";
import * as selectOptions from "@/helpers/selectOptions";

interface Idata {
  selectedLookupMethod: string;
  claimsLookUpByClaimsNumberInformation: any;
  claimsLookUpByPolicyNumberInformation: any;
  primaryButtonDisabled: boolean;
  messages: any[];
  loadingText: string;
}

export default Vue.extend({
  name: "ClaimsLookup",
  components: {
    Help,
    ClaimStatusLookup,
    ClaimStatusByClaimNumberLookupDetails,
    ClaimStatusByPolicyNumberLookupDetails
  },
  data(): Idata {
    return {
      selectedLookupMethod: "claimNumber",
      claimsLookUpByClaimsNumberInformation: {},
      claimsLookUpByPolicyNumberInformation: {},
      primaryButtonDisabled: false,
      messages: [
        {
          title: "Claims Inquiries",
          contacts: selectOptions.claimsInquiries
        },
        {
          title: "Claims Contacts",
          contacts: selectOptions.claimsContacts
        }
      ],
      loadingText: ""
    };
  },
  methods: {
    changeMethod($event: any) {
      (this.$refs.claimsLookUpComponent as any).keepData = true;
      (this.$refs.claimsLookUpComponent as any).lookupMethod = "claimNumber";
      (this.$refs
        .claimsLookUpComponent as any).claimNumber = this.claimsLookUpByClaimsNumberInformation.ClaimNumber;
      this.selectedLookupMethod = $event;
    },
    toolbarSelectItem(event: string) {
      switch (event) {
        case "lookup-claim":
          (this.$refs.claimsLookUpComponent as any).processClaimsLookup();
          break;
        case "view-breakdown":
          (this.$refs
            .ClaimStatusByClaimNumberLookupDetailsComponent as any).viewBreakdown();
          break;
        case "print-form":
          this.loadingText = "Printing form. Please wait...";
          if (this.selectedLookupMethod === "claimNumber") {
            (this.$refs
              .ClaimStatusByClaimNumberLookupDetailsComponent as any).doPrintClaimsLookupData();
          } else if (this.selectedLookupMethod === "policyNumber") {
            (this.$refs
              .ClaimStatusByPolicyNumberLookupDetailsComponent as any).printClaims(
              this.claimsLookUpByPolicyNumberInformation.data
            );
          }
          break;
        case "cancel":
          // this.claimsLookUpByClaimsNumberInformation = {};
          // this.claimsLookUpByPolicyNumberInformation = {};
          // this.selectedLookupMethod = "claimNumber";
          this.$router.push("/claims");
          break;
        default:
          break;
      }
    },
    clearData() {
      const { claimsLookUpComponent } = this.$refs;
      (claimsLookUpComponent as any).resetData();
    }
  },
  computed: {
    ...claimsMapState(["makingApiRequest"]),
    hasBreakDown(): boolean {
      const data: any = this.claimsLookUpByClaimsNumberInformation;
      return (
        data.DWG || data.UPP || data.ALE || data.MPO || data.SPP || data.OTS
      );
    },
    secondaryActions(): any[] {
      const actions = [
        {
          title: "Cancel",
          command: "cancel",
          loading: this.makingApiRequest
        }
      ];
      if (
        Object.keys(this.claimsLookUpByClaimsNumberInformation).length > 0 &&
        this.selectedLookupMethod === "claimNumber" &&
        this.hasBreakDown
      ) {
        actions.unshift({
          title: "View Breakdown",
          command: "view-breakdown",
          loading: this.makingApiRequest
        });
      }
      if (
        Object.keys(this.claimsLookUpByClaimsNumberInformation).length > 0 ||
        Object.keys(this.claimsLookUpByPolicyNumberInformation).length > 0
      ) {
        actions.unshift({
          title: "Print Form",
          command: "print-form",
          loading: this.makingApiRequest
        });
      }
      return actions;
    },
    actionButtonSecondary(): any {
      return this.secondaryActions.length
        ? {
            text: "Actions",
            key: "actions",
            subItems: this.secondaryActions
          }
        : {};
    }
  }
});
