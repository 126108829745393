var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],attrs:{"topActionButtonPrimary":{
    text: 'Lookup Claim',
    key: 'lookup-claim',
    loading: _vm.makingApiRequest,
    disabled: _vm.primaryButtonDisabled
  },"topActionButtonSecondary":_vm.actionButtonSecondary,"element-loading-text":_vm.loadingText},on:{"toolbarSelectItem":_vm.toolbarSelectItem}},[_c('div',{staticClass:"mb-8 md:mb-12 flex justify-between"},[_c('div',[_c('h3',{staticClass:"text-lg leading-6 font-medium text-gray-900"},[_vm._v(" Claims Search ")]),_c('p',{staticClass:"mt-1 text-sm text-gray-500"},[_vm._v(" Search for claims using either the policy number or claim number. ")])])]),_c('div',[_c('ClaimStatusLookup',{ref:"claimsLookUpComponent",on:{"sendBackClaimsLookUpByClaimsNumberInformation":function($event){_vm.claimsLookUpByClaimsNumberInformation = $event},"sendBackClaimsLookUpByPolicyNumberInformation":function($event){_vm.claimsLookUpByPolicyNumberInformation = $event},"lookupMethod":function($event){_vm.selectedLookupMethod = $event},"primaryButtonChange":function($event){_vm.primaryButtonDisabled = $event},"loadingText":function($event){_vm.loadingText = $event}}}),(
        Object.keys(_vm.claimsLookUpByClaimsNumberInformation).length > 0 ||
          Object.keys(_vm.claimsLookUpByPolicyNumberInformation).length > 0
      )?_c('IAButton',{staticClass:"float-right",attrs:{"click":_vm.clearData,"title":"Clear Results"}}):_vm._e(),(Object.keys(_vm.claimsLookUpByClaimsNumberInformation).length > 0)?_c('ClaimStatusByClaimNumberLookupDetails',{ref:"ClaimStatusByClaimNumberLookupDetailsComponent",attrs:{"claim":_vm.claimsLookUpByClaimsNumberInformation}}):_vm._e(),(Object.keys(_vm.claimsLookUpByPolicyNumberInformation).length > 0)?_c('ClaimStatusByPolicyNumberLookupDetails',{ref:"ClaimStatusByPolicyNumberLookupDetailsComponent",attrs:{"result":_vm.claimsLookUpByPolicyNumberInformation},on:{"sendBackClaimsLookUpByClaimsNumberInformation":function($event){_vm.claimsLookUpByClaimsNumberInformation = $event},"sendBackClaimsLookUpByPolicyNumberInformation":function($event){_vm.claimsLookUpByPolicyNumberInformation = $event},"lookupMethod":_vm.changeMethod,"loadingText":function($event){_vm.loadingText = $event}}}):_vm._e()],1),_c('Help',{attrs:{"title":"Claims Inquiries","messages":_vm.messages}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }