
import Vue from "vue";

export default Vue.extend({
  data: function() {
    return {
      open: true,
      hide: false
    };
  },
  props: {
    title: {
      type: String,
      default: "Do you need assistance?"
    },
    type: {
      type: String,
      default: "Mail"
    },
    messages: {
      type: Array
    },
    toggleByClick: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    toggle: function() {
      this.open = !this.open;
      this.$emit("toggle", this.open);
    },
    hideAll: function() {
      this.hide = !this.hide;
    }
  },
  watch: {
    toggleByClick: {
      immediate: true,
      handler: function(newVal: boolean) {
        this.open = newVal;
      }
    }
  }
});
